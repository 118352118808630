export const female = {
  Name: 'female',
  Quiz: {
    defaultProperties: [
      {
        question: 'gender',
        answer: 'female',
      },
    ],

    quizFlow: [
      {
        question: "What's the biggest benefit you want from losing weight?",
        questionCode: 'desired_outcome',
        answers: [
          '😊 Feeling comfortable in my own skin',
          '⚡ Having more energy every day',
          '📸 Finally loving how I look in photos',
          '💚 Improving my long-term health',
          "🤔 I'm not sure yet",
        ],
        type: 'question',
      },
      {
        question: 'Have you ever tried changing your weight before?',
        questionCode: 'past_attempts',
        answers: [
          '🔄 I lost some weight but gained it back',
          '🧗 I tried but never reached my goal',
          '🌱 No, this is my first attempt',
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/info_step_1.png',
        description:
          '<h3>Real Change Starts Inside 🌙</h3><br /><span>Most diets fail because they only scratch the surface. Hypnosis helps you shift the deep patterns that control your cravings and habits.</span><br /><br /><span>✨ Thousands trust Hypno each month to tap into their subconscious and make healthier eating feel effortless. Ready to see how?</span><br /><br /><span><i>"I never realized it could be this smooth and natural."</i> - Rachel B.</span>',
        button: 'Continue',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        question: 'What factor do you feel played the biggest role in your weight gain?',
        questionCode: 'main_cause',
        answers: [
          { text: '😰 Stress and emotional eating', image: '' },
          { text: '🕰️ Inconsistent eating times', image: '' },
          { text: '👵 Menopause-related changes', image: '' },
          { text: '🤱 Post-pregnancy weight', image: '' },
          { text: '😋 Cravings I just can’t resist', image: '' },
          { text: '💊 Medication side effects', image: '' },
          { text: '🤷 Not sure', image: '' },
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/info_step_2.png',
        description:
          '<h3>Why Hypnosis? 🌀</h3><br /><span>Instead of forcing willpower, hypnosis reprograms how you think about food. It turns healthy choices into second nature.</span><br /><br /><span>By easing internal stress, you break free from diets that never last. Your transformation is about to get simpler.</span>',
        button: 'Next',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        question: 'Do you believe in the power of hypnosis to ease unhealthy habits?',
        questionCode: 'hypnosis_trust',
        answers: [
          { text: '💪 Yes, I think it can help', image: '' },
          { text: '🌈 I’m open-minded, let’s see', image: '' },
          { text: '🤔 Not sure yet, but curious', image: '' },
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/info_step_3.png',
        description:
          "<h3>Getting Personal 🎯</h3><br /><span>We'll create a custom plan based on your details, so you know exactly how hypnosis will guide you towards easier, lasting weight loss.</span>",
        button: 'Continue',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        type: 'input',
        heading: 'How tall are you? 📏',
        questionCode: 'height_input',
        button: 'Continue',
        param: 'height',
      },
      {
        type: 'input',
        heading: 'What’s your current weight? ⚖️',
        questionCode: 'current_weight_input',
        button: 'Continue',
        param: 'weight',
      },
      {
        type: 'input',
        heading: 'What’s your goal weight? 🎯',
        questionCode: 'desired_weight_input',
        button: 'Continue',
        param: 'desired_weight',
      },
      {
        type: 'input',
        heading: 'How old are you? 🎂',
        questionCode: 'age_input',
        button: 'Continue',
        param: 'age',
      },
      {
        question:
          'How long have you been facing challenges like being overweight or binge eating?',
        questionCode: 'challenge_duration',
        answers: ['0–6 months', '6–12 months', '1–5 years', 'Over 5 years'],
        type: 'question',
      },
      {
        question: 'What physical symptoms do you usually experience?',
        questionCode: 'physical_symptoms',
        answers: [
          'Shortness of Breath',
          'Excessive Sweating',
          'Snoring',
          'Difficulty Sleeping',
          'Skin Issues',
          'Fatigue',
          'Back or Joint Pain',
          'Not Sure',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'How do these symptoms affect your life?',
        questionCode: 'symptom_impact',
        answers: [
          'Physical Discomfort',
          'Nervousness in Social Situations',
          'Anxiety About Traveling',
          'Difficulty at Work',
          'Low Self-Esteem',
          'Not Sure',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question:
          'How confident are you in achieving your goals with your current approach?',
        questionCode: 'confidence_level',
        answers: ['Confident', 'Somewhat Confident', 'Not at All'],
        type: 'question',
      },
      {
        question: 'How important is achieving this goal to you?',
        questionCode: 'importance_level',
        answers: ['Very Important', 'Important', 'Not Important'],
        type: 'question',
      },
      {
        question:
          'How much time can you dedicate daily to managing your weight loss journey?',
        questionCode: 'daily_time_commitment',
        answers: ['15 Minutes', '15–30 Minutes', '30–60 Minutes', 'More than 1 Hour'],
        type: 'question',
      },
      {
        question: 'When is the best time for you to listen to HypnoPlan?',
        questionCode: 'listening_time',
        answers: [
          'When I First Wake Up',
          'In the Morning',
          'At Lunchtime',
          'In the Afternoon/Evening',
          'Before I Fall Asleep',
        ],
        type: 'question',
      },
      {
        question: 'When you reach your goal, how do you plan to celebrate?',
        questionCode: 'celebration',
        answers: [
          { text: '💆 A relaxing spa day', image: '' },
          { text: '🤝 Doing something good for others', image: '' },
          { text: '🌱 Making a bold life change', image: '' },
          { text: '🏝️ Taking a dream vacation', image: '' },
        ],
        type: 'question',
      },
    ],
  },
};
